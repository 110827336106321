const countryCodeMap = {
    'us': 'usd',
    // Polish Zloty
    'pl': 'pln',
    // Indian Rupees
    'in': 'inr',
    'au': 'aud',
    'br': 'brl',
    'gb': 'gbp',
    'ca': 'cad',
    'sg': 'sgd',
    'at': 'eur',
    'be': 'eur',
    'cy': 'eur',
    'ee': 'eur',
    'fi': 'eur',
    'fr': 'eur',
    'de': 'eur',
    'gr': 'eur',
    'ie': 'eur',
    'it': 'eur',
    'lv': 'eur',
    'lt': 'eur',
    'lu': 'eur',
    'mt': 'eur',
    'nl': 'eur',
    'pt': 'eur',
    'sk': 'eur',
    'si': 'eur',
    'es': 'eur',
    // Japanese Yen
    'jp': 'jpy',
    // Philippine peso
    'ph': 'php',
    'tr': 'try'
};

const continentCodeMap = {
    'af': 'usd',
    'na': 'usd',
    'sa': 'brl',
    'eu': 'eur',
    'oc': 'aud',
    'as': 'usd',
    'an': 'usd'
}

function getCountryCode(userLocation){
    // const startIdx = userLocation.indexOf("geoplugin_countryCode") + 34;
    // const endIdx = startIdx + 2;
    //
    // return userLocation.substring(startIdx, endIdx).toLowerCase()
    return userLocation['country_code'].toLowerCase()
}

function getContinentCode(userLocation){
    // const startIdx = userLocation.indexOf("geoplugin_continentCode") + 36;
    // const endIdx = startIdx + 2;
    //
    // return userLocation.substring(startIdx, endIdx).toLowerCase()
    return userLocation['continent_code'].toLowerCase()
}

function getCurrency(userLocation){
    const countryCode = getCountryCode(userLocation);
    const continentCode = getContinentCode(userLocation);

    if (countryCode in countryCodeMap){
        return countryCodeMap[countryCode]
    }
    else if (continentCode in continentCodeMap){
        return continentCodeMap[continentCode]
    }
    else{
        return "usd"
    }


    // 'continent_code': 'NA', 'continent_name': 'North America', 'country_code': 'US', 'country_name': 'United States',
}

export default getCurrency;