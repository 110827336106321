import React, {Component} from 'react'
import { isMobile} from 'react-device-detect'
import ResetButton from "../Index/ResetButton"
import {isEmbed} from "../Constants/isEmbed";

class LandingPageHero extends Component {
    render() {
        if (isEmbed(window.location.href)){
            return null
        } else {
            let textStyle = {textAlign: 'center', paddingBottom: '20px'};

            if (isMobile){
                textStyle = {textAlign: 'center', padding: '10px'};
            }

            return (
                <section className="heroSection">
                    <div className="row">
                        <div className="col-sm-1" />
                        <div className="col-sm-2">
                            <div className="row">
                                <div className="col-sm-2">

                                </div>
                                <div className="col-sm-10">
                                    <ResetButton/>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div style={textStyle}>
                                <h1 className="thinHeading">Split Spreadsheets</h1>
                                <h4 className="thinHeading text-muted">Automatically split large spreadsheets with one click</h4>
                            </div>
                        </div>
                        <div className="col-sm-3" />
                    </div>
                </section>
            )
        }

    }
}

export default LandingPageHero