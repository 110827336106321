import React from 'react'
import { Route, Switch } from "react-router";
import LandingPageIndex from './LandingPage/LandingPageIndex'
import NotFound from './InfoPages/NotFound'
import PrivacyPolicy from './InfoPages/PrivacyPolicy'
import TermsAndConditions from './InfoPages/TermsAndConditions'
import LoginIndex from './Authentication/UserPages/LogIn/LoginIndex'
import SignUpIndex from './Authentication/UserPages/SignUp/SignUpIndex'
import ResetPasswordIndex from './Authentication/UserPages/Password/ResetPasswordIndex'
import HelpIndex from "./InfoPages/Help/HelpIndex"
import PricingPage from "./InfoPages/Pricing/PricingPage"
import UpdatePaymentIndex from "./SharedComponents/UpdatePayment/UpdatePaymentIndex"

export default ({ childProps }) =>
    <Switch>
        <Route exact path='/' component={LandingPageIndex} />
        <Route exact path='/FAQs' component={HelpIndex} />
        <Route exact path='/update-payment-info' component={UpdatePaymentIndex} />
        <Route exact path='/terms' component={TermsAndConditions} />
        <Route exact path='/privacy' component={PrivacyPolicy} />
        <Route exact path="/login" component={LoginIndex} />
        <Route exact path="/signup" component={SignUpIndex} />
        <Route exact path='/reset-password/:id' component={ResetPasswordIndex} />
        <Route exact path='/pricing' component={PricingPage} />
        <Route component={NotFound}/>
    </Switch>;