import React, {Component} from 'react'

class CustomRating extends Component {
    constructor(props){
        super(props);

        this.state = {
            hoverRating: 0
        }
    }

    updateRating = (e, idx) => {
        this.setState({ hoverRating: 0 }, () => this.props.updateRating(parseInt(idx)))
    }

    updateHoverRating = (e, idx, enter) => {
        if (enter){
            this.setState({ hoverRating: idx })
        } else {
            this.setState({ hoverRating: this.props.rating })
        }
    }

    render() {
        const iconStyle = {fontSize: "200%", cursor: "pointer"};

        let stars = [];
        for (let i=1; i<=5; i++){
            if (i <= this.props.rating){
                stars.push(<i className="fa fa-star fa-2x"
                              onClick={e => this.updateRating(e, i)}
                              style={iconStyle} />)
            } else {
                if (i <= this.state.hoverRating){
                    stars.push(<i className="fa fa-star fa-2x"
                                  onClick={e => this.updateRating(e, i)}
                                  onMouseEnter={e => this.updateHoverRating(e, i, true)}
                                  onMouseLeave={e => this.updateHoverRating(e, i, false)}
                                  style={iconStyle} />)
                } else {
                    stars.push(<i className="fa fa-star-o fa-2x"
                                  onClick={e => this.updateRating(e, i)}
                                  onMouseEnter={e => this.updateHoverRating(e, i, true)}
                                  onMouseLeave={e => this.updateHoverRating(e, i, false)}
                                  style={iconStyle} />)
                }
            }
        }

        return (
            <div style={{color: 'purple'}}>
                {stars}
            </div>
        )
    }
}

export default CustomRating