import React, {Component} from 'react'
import { isEmbed } from "../Constants/isEmbed";

class LovePromoBanner extends Component {
    render() {
        if (isEmbed(window.location.href)){
            return null
        } else {
            return (
                <div style={{ backgroundColor: "#EE685F",
                    color: "white", padding: "8px", fontWeight: "700", textAlign: "center" }}>
                    Need any other help with Spreadsheets? <a href="https://www.lovespreadsheets.com/" target="_blank" rel="noopener noreferrer" style={{ color: "whitesmoke" }}>Click here to let us do your spreadsheet work</a>
                </div>
            )
        }
    }
}

export default LovePromoBanner