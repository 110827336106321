import React, {Component} from 'react'
import { Button } from 'semantic-ui-react'
import API_Root from "../../Constants/API_Root";
import { connect } from 'react-redux'
import {toggleModal} from "../../Actions/ToggleModal";

class FeedbackSubmitButton extends Component {
    constructor(props) {
        super(props);

        this.submitFeedback = this.submitFeedback.bind(this);
    }

    submitFeedback(){
        fetch(API_Root + "api/submit-feedback/", {
            method: 'POST',
            headers: {
                Accept: 'application/json, text/plain, */*',
            },
            body: JSON.stringify({
                feedback: this.props.feedback,
                rating: this.props.rating
            })
        }).then((res) => {alert("Thanks for your feedback!"); this.props.clearStates(); this.props.toggleModal(null)})
            .catch(err => {alert("Your feedback could not be submitted :( Please try again"); this.props.toggleModal(null)});
    }

    render() {
        return (
            <Button color="purple" onClick={this.submitFeedback}>Submit Feedback</Button>
        )
    }
}

const mapActionsToProps = {
    toggleModal: toggleModal
}

export default connect(null,mapActionsToProps)(FeedbackSubmitButton);