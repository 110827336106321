import React, {Component} from 'react'
import CreditCardDetails from './CreditCardDetails'
import {Elements, StripeProvider} from 'react-stripe-elements';
import { connect } from 'react-redux'
import {togglePro} from "../../Actions/TogglePro";
import getCurrencyAmount from "../../Constants/getCurrencyAmount";
import {modifyCurrency} from "../../Actions/ModifyCurrency";

class PaymentForm extends Component {
    constructor(props){
        super(props);

        this.changeProcess = this.changeProcess.bind(this);
        this.modifyPaymentCurrency = this.modifyPaymentCurrency.bind(this);
    }

    changeProcess(process){
        this.props.changeProcess(process);
        this.props.togglePro(true);
    }

    modifyPaymentCurrency(value){
        this.props.modifyCurrency(value)
    }

    render() {
        return (
            <div>
                <StripeProvider apiKey={window.location.href.includes("localhost") ? "pk_test_1rS8QGgeDU1Ewoyjc8AjaTx0" : "pk_live_nmeaYWsNdAbSbOTRd8MgnGLL"}>
                    <div className="example">
                        <Elements>
                            <CreditCardDetails changeProcess={this.changeProcess}
                                               currency={this.props.currency}
                                               currencyAmount={getCurrencyAmount(this.props.currency)}
                                               modifyPaymentCurrency={this.modifyPaymentCurrency}
                            />
                        </Elements>
                    </div>
                </StripeProvider>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    currency: state.mainState.currency
});

const mapActionsToProps = {
    togglePro: togglePro,
    modifyCurrency: modifyCurrency
};

export default connect(mapStateToProps, mapActionsToProps)(PaymentForm)
