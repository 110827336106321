import {TOGGLE_MODAL} from "../Actions/ToggleModal";
import {MODIFY_FILE_PK} from "../Actions/ModifyFilePK";
import {MODIFY_FILE_INFORMATION} from "../Actions/ModifyFileInformation";
import {MODIFY_STATUS} from "../Actions/ModifyStatus";
import {MODIFY_EDIT_OPTIONS} from "../Actions/ModifyEditOptions";
import {RESET_FILE_STATE} from "../Actions/ResetFileState";
import {RESET_STATE_AFTER_UPLOAD} from "../Actions/ResetStateAfterUpload";
import {TOGGLE_PRO} from "../Actions/TogglePro";
import {MODIFY_CURRENCY} from "../Actions/ModifyCurrency";

function mainState(
    state = {
        filePK: -1,
        fileInformation: {},
        editOptions: {
            header: true,
            startRow: "",
            startRowError: false,
            delimiter: null,
        },
        modalName: null,
        status: "upload",
        pro: false,
        currency: "usd"
    }, action
) {
    switch (action.type) {
        case MODIFY_FILE_PK:
            return Object.assign({}, state, {
                filePK: action.mainState.filePK
            });
        case MODIFY_FILE_INFORMATION:
            return Object.assign({}, state, {
                fileInformation: action.mainState.fileInformation
            });
        case MODIFY_EDIT_OPTIONS:
            return Object.assign({}, state, {
                editOptions: action.mainState.editOptions
            });
        case TOGGLE_MODAL:
            return Object.assign({}, state, {
                modalName: action.mainState.modalName
            });
        case MODIFY_STATUS:
            return Object.assign({}, state, {
                status: action.mainState.status
            });
        case TOGGLE_PRO:
            return Object.assign({}, state, {
                pro: action.mainState.pro
            });
        case MODIFY_CURRENCY:
            return Object.assign({}, state, {
                currency: action.mainState.currency
            });
        case RESET_FILE_STATE:
            return Object.assign({}, state, {
                filePK: -1,
                fileInformation: {},
                editOptions: {
                    header: true,
                    startRow: "",
                    startRowError: false,
                    delimiter: null,
                },
                modalName: null,
                status: "upload"
            });
        case RESET_STATE_AFTER_UPLOAD:
            return Object.assign({}, state, {
                editOptions: action.mainState.editOptions,
                sheetRead: action.mainState.sheetRead,
                selectedColumns: action.mainState.selectedColumns,
                cleanOptionType: action.mainState.cleanOptionType,
                customOptions: action.mainState.customOptions,
                cleanResults: action.mainState.cleanResults,
                cleanResultsOptions: action.mainState.cleanResultsOptions
            });
        default:
            return state
    }
}

export default mainState;

