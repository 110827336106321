import React, {Component} from 'react'
import LandingPageHero from "./LandingPageHero";
import { MobileView, BrowserView } from 'react-device-detect'
import MobileLandingPage from './MobileLandingPage';
import {toggleModal} from "../Actions/ToggleModal";
import {push} from "react-router-redux";
import {connect} from "react-redux";
import AppIndex from '../Index/AppIndex'
import AuthComponent from "../SharedComponents/AuthComponent";
import FailedPaymentMessage from "../SharedComponents/FailedPaymentMessage";

class LandingPageIndex extends Component {
    render() {
        return (
            <div id="appAuthentication">
                <AuthComponent
                    authComponent={<FailedPaymentMessage/>}
                    nonAuthComponent={null}
                />
                <BrowserView>
                    <br/><br/>
                </BrowserView>
                <MobileView>
                    <br/><br/><br/>
                </MobileView>
                <LandingPageHero/>
                <BrowserView>
                    <AppIndex />
                    <br/><br/><br/><br/>
                </BrowserView>
                <MobileView>
                    <MobileLandingPage/>
                </MobileView>
                <br/><br/><br/><br/><br/>
            </div>
        )
    }
}

const mapActionsToProps = {
    navigateTo: push,
    toggleModal: toggleModal
}

export default connect(null,mapActionsToProps)(LandingPageIndex)