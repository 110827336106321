import React, {Component} from 'react'
import {connect} from 'react-redux'
import {modifyEditOptions} from "../../Actions/ModifyEditOptions";
import { Icon, Input, Dropdown } from 'semantic-ui-react'
import EditFileReadButton from "./EditFileReadButton"

class EditFileOptionsBody extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sheetName: this.props.fileInformation.sheetName
        }

    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        const currOptions = JSON.parse(JSON.stringify(this.props.editOptions))

        if (name === "startRow"){
            currOptions['startRowError'] = this.error(value)
        }

        currOptions[name] = value

        this.props.modifyEditOptions(currOptions)
    }

    error = (value) => {
        let error = true;

        if (value.trim() === ""){
            error = false
        }
        else if (!isNaN(value)){
            if (parseInt(value) >= 0){
                error = false;
            }
        }

        return error
    }

    changeDelimiter = (event, {value}) => {
        const currOptions = JSON.parse(JSON.stringify(this.props.editOptions))
        currOptions["delimiter"] = value;

        this.props.modifyEditOptions(currOptions)
    }

    changeSheetName = (event, {value}) => {
        this.setState({ sheetName: value })
    }

    render() {
        const sheetInput = this.state.sheetName !== null ?
            <div style={{display: 'block', marginBottom: "20px"}}>
                <span className="thinHeading">Sheet Name
                    {' '}
                    <Dropdown
                        placeholder="Select Sheet"
                        name="sheetName"
                        value={this.state.sheetName}
                        options={this.props.fileInformation.sheetNames.map((x, idx) => { return { key: idx, text: x, value: x} })}
                        onChange={this.changeSheetName}
                        inline
                    />
                </span>
            </div> : null

        const delimiterOptions = [
            {key: 1, text: 'Comma (,)', value: ','},
            {key: 2, text: 'Semi-Colon (;)', value: ';'},
            {key: 3, text: 'Pipe (|)', value: '|'},
            {key: 4, text: 'Tab', value: 'tab'},
            {key: 5, text: 'Space ( )', value: 'space'},
        ];

        const delimiterInput = this.props.editOptions.delimiter !== null ?
            <div style={{display: 'block', marginBottom: "20px"}}>
                <span className="thinHeading">Delimiter
                    {' '}
                    <Dropdown
                        placeholder="Select Delimiter"
                        name="delimiter"
                        value={this.props.editOptions.delimiter}
                        options={delimiterOptions}
                        onChange={this.changeDelimiter}
                        inline
                    />
                </span>
            </div> : null;


        return (
            <div style={{paddingBottom: "30px"}}>
                <h2 style={{marginBottom: "20px"}}><Icon name="edit alternate outline"/> Re-read file with different options</h2>
                <div style={{display: 'block', marginBottom: "20px"}}>
                    <p className="thinHeading">Start at Row
                    <Input
                        style={{marginLeft: "10px"}}
                        onChange={this.handleInputChange}
                        name="startRow"
                        error={this.props.editOptions.startRowError}
                        placeholder="3"
                        value={this.props.editOptions.startRow} /></p>
                    {this.props.editOptions.startRowError ? <small style={{color: 'red'}}>Please enter only a single number</small> : null}
                </div>
                <div style={{display: 'block', marginBottom: "20px"}}>
                    <input style={{cursor: "pointer"}} type="checkbox" name="header" id="header" onChange={this.handleInputChange} checked={this.props.editOptions.header} />
                    <label htmlFor="header" style={{marginLeft: "10px", cursor: "pointer"}}>
                        <span>Does File Have a Header?</span>
                    </label>
                </div>
                {delimiterInput}
                {sheetInput}
                <EditFileReadButton sheetName={this.state.sheetName} />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    editOptions: state.mainState.editOptions,
    fileInformation: state.mainState.fileInformation
})

const mapActionsToProps = {
    modifyEditOptions: modifyEditOptions
}

export default connect(mapStateToProps, mapActionsToProps)(EditFileOptionsBody)