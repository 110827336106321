import React, {Component} from 'react'
import API_Root from '../../../Constants/API_Root'
import { auth } from '../../firebase';
import { Header, Input, Button, Message } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import {toggleModal} from "../../../Actions/ToggleModal";
import constants from "../../../Constants/constants";
import { Icon } from 'semantic-ui-react'

class SignUpForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email:'',
            passwordOne:'',
            passwordTwo:'',
            tandc: false,
            error: null,
            loading: false
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.signUp = this.signUp.bind(this);
        this.dismissMessage = this.dismissMessage.bind(this);
    }

    dismissMessage(e){
        this.setState({ error: null });
    }

    handleInputChange(e){
        if (e.target.name === "tandc"){
            this.setState({
                tandc: !this.state.tandc
            })
        }
        else{
            this.setState({
                [e.target.name]: e.target.value
            })
        }
    }

    signUp(e){
        this.setState({
            loading: true
        })

        const email = this.state.email;
        const passwordOne = this.state.passwordOne;

        const API = API_Root + 'api/save-user/'; /*save user email to our db*/
        const formData = {
            "email": email,
            "username": email,
            "password": passwordOne
        };


        auth.doCreateUserWithEmailAndPassword(email, passwordOne)
            .then(authUser => {
                this.setState(() => ({
                    username: '',
                    email: '',
                    passwordOne: '',
                    passwordTwo: '',
                    tandc: false,
                    error: null,
                }));
                fetch(API, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(formData)
                })
                    .then(res => res.json())
                    .then((data) => {
                            localStorage.setItem("email", email);
                            localStorage.setItem("token", data.token);
                            this.setState({
                                loading: false
                            });
                            this.props.toggleModal(null);

                            this.props.navigateTo("/upload");
                        }
                    ).catch(err => this.setState({loading: false, error: {message: 'Try again'}}));
            })
            .catch(error => {
                this.setState({error:  error, loading: false});
            });
    }

    render() {
        const {
            email,
            passwordOne,
            passwordTwo,
            tandc,
            error
        } = this.state;

        const isInvalid = passwordOne !== passwordTwo || passwordOne === '' || passwordOne.length < 8 ||email === ''|| tandc === false;

        const passwordLengthError = passwordOne.length < 8 ? <div><small style={{color: 'red'}}>Password must be at least 8 characters</small></div> : null;
        const passwordMatchError = passwordOne !== passwordTwo ? <div><small style={{color: 'red'}}>Passwords Do Not Match</small></div> : null;

        return (
            <div>
                    <Header as='h1'>
                        Sign Up
                        <Header.Subheader>
                            Start cleaning your spreadsheets
                        </Header.Subheader>
                    </Header>
                    <br/>
                    <Input icon="at"
                           iconPosition='left'
                           placeholder="Email" type="email" name="email"
                           onChange={this.handleInputChange}
                           value={email} fluid/>
                    <Input  icon="lock"
                            iconPosition="left"
                            placeholder="Password (at least 8 characters)" type="password" name="passwordOne"
                            onChange={this.handleInputChange}
                            value={passwordOne} error={passwordOne.length < 8} fluid/>
                    <Input  icon="lock"
                            iconPosition="left"
                            placeholder="Confirm Password" type="password" name="passwordTwo"
                            onChange={this.handleInputChange}
                            value={passwordTwo} error={passwordTwo.length < 8} fluid/>
                    <br/>
                    <input type="checkbox" name="tandc" id="tandc" onChange={this.handleInputChange} checked={tandc} />
                    <label style={{cursor: 'pointer'}} htmlFor="tandc">
                        <span style={{paddingLeft: '7px'}}>{"I agree with " + constants.productName + "'s"}</span>
                        <a href="/terms" target="_blank"> Terms and Conditions</a>
                    </label>
                    {passwordLengthError}
                    {passwordMatchError}
                    <br/>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <Button loading={this.state.loading} color="blue" disabled={isInvalid} onClick={this.signUp} style={{fontSize: '120%'}}>
                            Sign up and go to your dashboard <Icon name="right arrow" />
                        </Button>
                    </div>
                    { error && <Message error
                                        onDismiss={this.dismissMessage}
                                        header={error.message} /> }
            </div>
        )
    }
}

const mapActionsToProps = {
    navigateTo: push,
    toggleModal: toggleModal
}

export default connect(null,mapActionsToProps)(SignUpForm);
