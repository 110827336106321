import React, {Component} from 'react'
import {connect} from 'react-redux'
import LoginForm from "../../Authentication/UserPages/LogIn/LoginForm";
import { Segment } from 'semantic-ui-react'

class UpdatePaymentLogin extends Component {
    render() {
        return (
            <div>
                <div style={{padding:'30px',backgroundColor:'#f88479', color:'white',margin:'25px 15px 25px 15px', borderStyle:'solid', borderWidth:'1px',textAlign:'center'}}>
                    <h5> Please login before updating payment information </h5>
                </div>
                <Segment>
                    <LoginForm modal/>
                </Segment>
            </div>

        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(UpdatePaymentLogin)
