import React, {Component} from 'react'
import { Segment, Input, Button, Message } from 'semantic-ui-react'
import API_Root from "../Constants/API_Root"

class MobileLandingPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            message: null,
            loading: false,
        }

        this.sendEmail = this.sendEmail.bind(this);
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    sendEmail(e){
        this.setState({ loading: true });

        const stopLoading = () => this.setState({ loading: false });

        fetch(API_Root + "api/send-email/", {
            method: 'POST',
            headers: {
                Accept: 'application/json, text/plain, */*',
            },
            body: JSON.stringify({
                email: this.state.email,
                fromEmail: 'info@splitspreadsheets.com'
            })
        }).then(res =>
            this.setState({
                message: <Message positive={true}
                                  header="Email sent successfully!"
                                  content="It is possible the email is sent to the spam or junk folder" />,
                email: '',
                loading: false
            })
        ).catch(err => stopLoading());
    }

    render() {
        return (
            <div style={{marginTop: '50px', marginBottom: '100px', textAlign: 'center', marginLeft: '20px', marginRight: '20px'}}>
                <Segment raised>
                    <h3 className="thinHeading">Thanks for visiting <span role="img" aria-label="heart">❤️</span> <br/>This app is meant to be used on the desktop </h3>
                    <br/>
                    <h5 className="thinHeading">Email yourself the website link</h5>
                    <Input onChange={this.handleInputChange} placeholder="Enter Email" name="email" fluid />
                    <br/>
                    <Button loading={this.state.loading} onClick={this.sendEmail} color="purple" icon="paper plane" content="Email yourself"/>
                    <br/>
                    {this.state.message}
                </Segment>
            </div>
        )
    }
}

export default MobileLandingPage