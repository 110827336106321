import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Icon, Checkbox, Popup } from 'semantic-ui-react'
import Loader from 'react-loader-spinner'
import isObjectEmpty from "../Constants/isObjectEmpty";
import {toggleModal} from "../Actions/ToggleModal";
import DownloadSplitButton from "./DownloadSplits/DownloadSplitButton"

class FileOptions extends Component {
    constructor(props) {
        super(props);

        this.state = {
            headerRow: true,
            recordsInEachFile: "5000",
            recordError: false
        }

        this.changeCheckbox = this.changeCheckbox.bind(this);
        this.changeRecords = this.changeRecords.bind(this);
        this.isRecordValid = this.isRecordValid.bind(this);
    }

    changeCheckbox(){
        this.setState({ headerRow: !this.state.headerRow })
    }

    changeRecords(e){
        const records = e.target.value;

        if (this.isRecordValid(records)){
            this.setState({
                recordError: false
            })
        }
        else{
            this.setState({
                recordError: true
            })
        }

        this.setState({ recordsInEachFile: records })
    }

    isRecordValid(record){
        let valid = false;

        if (record.trim() === ""){
            valid = false
        }
        else if (!isNaN(record)){
            if (parseInt(record) >= 0 && parseInt(record) <= 10000){
                valid = true
            }
        }

        return valid
    }

    openModal = (e, modalName) => {
        this.props.toggleModal(modalName)
    }

    render() {
        if (this.props.status === "reading" || this.props.status === "saved"){
            return(
                <div id="uploadFileOptions" className="card">
                    <Loader
                        type="TailSpin"
                        color="black"
                        height="50"
                        width="50"
                    />
                </div>
            )
        }
        else{
            let fileName = "No file uploaded yet"
            let style = {
                pointerEvents: 'none',
                opacity: '0.4'
            }
            let fileRow = null
            let sheetRead = null

            if (!isObjectEmpty(this.props.fileInformation)){
                if ('name' in this.props.fileInformation){
                    fileName = this.props.fileInformation.name;
                }

                if ('sheetName' in this.props.fileInformation){
                    sheetRead = this.props.fileInformation.sheetName !== null ? <span style={{fontSize: "14px"}}><b>Sheet Name:</b> {this.props.fileInformation.sheetName}</span> : null
                }

                style = null

                fileRow =
                <div className="row" style={{marginBottom: "30px"}}>
                    <div className="col-sm-6">
                        <p className="linkStyle" onClick={e => this.openModal(e, "sampleDataModal")}><Icon name="table"/> View File Data</p>
                    </div>
                    <div className="col-sm-6" style={{textAlign: 'right'}}>
                        <p className="linkStyle" onClick={e => this.openModal(e, "editFileOptionsModal")}><Icon name="edit alternate outline"/> Read File Options</p>
                    </div>
                </div>
            }

            return (
                <div id="uploadFileOptions" className="card" style={style}>
                    <h2 style={{color: 'purple', wordBreak: "break-all"}}>{fileName} {sheetRead}</h2>
                    {fileRow}
                    <div style={{border: "1px solid lightgrey", borderRadius: "5px", padding: "15px"}}>
                        <h5 style={{marginBottom: "25px"}}><Icon name="options" /> Specify Options and Split Your File</h5>
                        <div className="fileOptionsInputDiv">
                            <Checkbox
                                label="Include Header In Split File?"
                                onChange={this.changeCheckbox}
                                checked={this.state.headerRow}
                            />
                            <Popup content={<h5>Checking this will add your header on top of each file</h5>}
                                   position='left center'
                                   trigger={<span className="linkStyle" style={{marginLeft: "10px"}}>what is this?</span>} />
                        </div>
                        <div className="fileOptionsInputDiv">
                            <input
                                value={this.state.recordsInEachFile}
                                onChange={this.changeRecords}
                                id="recordsInFile"
                            />
                            <label htmlFor="recordsInFile" style={{paddingLeft: "10px"}}>rows in each split file</label>
                            <br/>
                            {this.state.recordError ? <small style={{color: 'red'}}><i>Enter a number less than 10,000</i></small> : <small><i>Max 10,000 rows per file</i></small>}
                        </div>
                        <DownloadSplitButton
                            downloadOptions={this.state}
                        />
                    </div>
                </div>
            )
        }
    }
}

const mapStateToProps = (state) => ({
    status: state.mainState.status,
    fileInformation: state.mainState.fileInformation
})

const mapActionsToProps = {
    toggleModal: toggleModal
}

export default connect(mapStateToProps, mapActionsToProps)(FileOptions)