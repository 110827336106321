import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Button } from 'semantic-ui-react'
import {toggleModal} from "../../Actions/ToggleModal";
import isObjectEmpty from "../../Constants/isObjectEmpty";
import API_Root from "../../Constants/API_Root";
import pricing from "../../Constants/pricing";
import {hasUserMergedWithinTimeframe} from "../../Constants/hasUserMergedWithinTimeframe";
import ProBlockMessage from "../ProBlockMessage"

class DownloadSplitButton extends Component {
    constructor(props) {
        super(props);

        this.state = {
            downloadURL: "",
            fileName: "",
            loading: false
        }


        this.splitAndDownloadFile = this.splitAndDownloadFile.bind(this);
    }

    splitAndDownloadFile(e){
        this.setState({
            loading: true
        })

        this.props.toggleModal("loadingModalDownloadFile");

        const editOptions = JSON.parse(JSON.stringify(this.props.editOptions))
        editOptions['skipRows'] = ""

        const websocketRoute = API_Root.substring(0, 5) === "https" ? API_Root.replace("https", "wss") : API_Root.replace("http", "ws");
        const socket = new WebSocket(websocketRoute + "ws/api/split-file/");

        const done = () => {
            this.setState({ loading: false })
            this.props.toggleModal(null)
            socket.close()
        }

        socket.onopen = (event) => {
            socket.send(JSON.stringify({
                filePK: this.props.filePK,
                fileInformation: this.props.fileInformation,
                editOptions: editOptions,
                recordsInEachFile: this.props.downloadOptions.recordsInEachFile,
                headerRow: this.props.downloadOptions.headerRow
            }));
        };

        socket.onmessage = (event) => {
            const reply = JSON.parse(event.data);
            const status = reply['status'];

            if (status === "download"){
                this.setState({ downloadURL: reply['downloadURL'] }, () => this.downloadLink.click())
                localStorage.setItem("lastSplit", new Date())
                done()
                this.props.toggleModal("feedbackModal")
            }
            else if (status === "error"){
                done()
                alert("There was a problem splitting your file. Please try again!")
            }
        };
    }

    canUserDownload = () => {
        let canDownload = true;

        if (!this.props.pro){
            if (!isObjectEmpty(this.props.fileInformation)){
                if (this.props.fileInformation.size > pricing.mbMax || hasUserMergedWithinTimeframe()){
                    canDownload = false
                }
            }
        }

        return canDownload
    }


    render() {
        let btnDisabled = false;

        if (isObjectEmpty(this.props.fileInformation)){
            btnDisabled = true
        } else {
            if (this.props.downloadOptions.recordError || !this.canUserDownload()){
                btnDisabled = true
            }
        }

        const downloadURL = this.state.downloadURL;

        return (
            <div>
                <div style={{marginTop: "25px"}}>
                    <a href={downloadURL}
                       ref={downloadLink => this.downloadLink = downloadLink}
                       style={{display: 'none'}}>
                        Download
                    </a>
                    <Button
                        content="Split Your File"
                        icon="download"
                        color="green"
                        fluid
                        size="huge"
                        onClick={this.splitAndDownloadFile}
                        disabled={btnDisabled}
                        loading={this.state.loading}
                    />
                    <div style={{textAlign: "center"}}>
                        <small><i>All split files will be downloaded in a ZIP file in .csv format</i></small>
                    </div>
                </div>
                {!this.canUserDownload() ? <div style={{marginTop: "10px"}}><ProBlockMessage /></div> : null}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    fileInformation: state.mainState.fileInformation,
    editOptions: state.mainState.editOptions,
    filePK: state.mainState.filePK,
    pro: state.mainState.pro
})

const mapActionsToProps = {
    toggleModal: toggleModal
}

export default connect(mapStateToProps, mapActionsToProps)(DownloadSplitButton)