import React, {Component} from 'react'
import { Divider } from 'semantic-ui-react'
import {toggleModal} from "../../Actions/ToggleModal";
import { connect } from 'react-redux'

class FAQ extends Component{
    render() {
        const answer = this.props.answer;
        let answerBlock;

        if (answer.constructor === Array){
            const answerList = answer.map((x, idx) => <li key={idx}>{x}</li>);
            answerBlock = <ul>{answerList}</ul>
        }
        else if (typeof answer === "string"){
            answerBlock = <p>{answer}</p>
        } else {
            answerBlock = answer
        }

        return (
            <div>
                <h5>{this.props.question}</h5>
                {answerBlock}
                <Divider/>
            </div>
        )
    }
}

const mapActionsToProps = {
    toggleModal: toggleModal
}

export default connect(null, mapActionsToProps)(FAQ)