import React, {Component} from 'react'

class FAQBlock extends Component {
    render() {
        return (
            <div className="faqBlock">
                <h5>{this.props.question}</h5>
                {this.props.answer}
            </div>
        )
    }
}

export default FAQBlock