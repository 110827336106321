export const isUserPro = (pricingObject) => {
    let userPro = false;

    if ('pricingPlan' in pricingObject){
        if (pricingObject['pricingPlan'] === "pro"){
            if ('subscription_id' in pricingObject){
                if ('cancel' in pricingObject){
                    if (!pricingObject['cancel']){
                        userPro = true
                    }
                    else{
                        const endDateInt = pricingObject.currentPeriodEnd;
                        const endDate = new Date(parseInt(endDateInt, 10) * 1000)

                        const currDate = new Date();

                        // if date
                        if (endDate > currDate){
                            userPro = true
                        }
                    }
                }
                else{
                    userPro = true
                }
            }
            else{
                userPro = true
            }
        }
    }

    return userPro
}