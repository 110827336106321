function getCurrencyAmount(currency){
    const pricing_currency = {
        'usd': '$4',
        // Polish Zloty
        'pln': 'zł12',
        // Indian Rupees
        'inr': '₹81',
        'aud': 'AUD $6',
        'brl': 'R$8',
        'gbp': '£3',
        'cad': 'CAD $5',
        'sgd': 'SGD $5',
        'eur': '€4',
        // Japanese Yen
        'jpy': '¥400',
        // Philippine peso
        'php': '₱80',
        'try': '₺14'
    }

    return pricing_currency[currency]
}

export default getCurrencyAmount