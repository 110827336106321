export const RESET_STATE_AFTER_UPLOAD = 'RESET_STATE_AFTER_UPLOAD';

export function resetStateAfterUpload(){
    return{
        type: RESET_STATE_AFTER_UPLOAD,
        mainState: {
            editOptions: {
                header: true,
                skipRows: "",
                startRow: "",
                skipRowsError: false,
                startRowError: false,
                delimiter: null
            },
            sheetRead: null,
            selectedColumns: [],
            cleanOptionType: {
                name: "smart",
                address: "smart",
                company: "smart",
                date: "smart",
                email: "smart"
            },
            customOptions: {
                name: {
                    capitalization: 'title',
                    nameFormat: 'first_middle_last'
                },
                address: {
                    minPercent: '80',
                    percentError: false
                },
                company: {
                    minPercent: '80',
                    percentError: false
                },
                email: {
                    minPercent: '80',
                    percentError: false
                },
                date: {
                    dateFormat: '%Y-%m-%d'
                },
            },
            cleanResults: {
                // Key of Column Idx and then object with results
                name: {},
                address: {},
                company: {},
                email: {},
                date: {},
            },
            cleanResultsOptions:{
                // Options
                name: {pk: -1},
                address: {pk: -1},
                company: {pk: -1},
                email: {pk: -1},
                date: {pk: -1},
            }
        }
    }
}