import React, {Component} from 'react'
import {connect} from 'react-redux'
import ModalApp from "../../SharedComponents/Modal"
import isObjectEmpty from "../../Constants/isObjectEmpty";
import EditFileOptionsBody from "./EditFileOptionsBody"

class EditFileOptionsModal extends Component {
    render() {
        let body = null;

        if (!isObjectEmpty(this.props.fileInformation)){
            body = <EditFileOptionsBody />
        }
        return (
            <ModalApp
                name="editFileOptionsModal"
                header={null}
                body={body}
                size="small"
            />
        )
    }
}

const mapStateToProps = (state) => ({
    fileInformation: state.mainState.fileInformation
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(EditFileOptionsModal)