import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Input, Form, Button, TextArea, Icon, Message, Popup } from 'semantic-ui-react'
import emailIsValid from "../../Constants/isEmailValid";
import bytesToMB from '../../Constants/bytesToMB'
import API_Root from '../../Constants/API_Root'

class SupportForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name: '',
            email: localStorage.getItem("email"),
            subject: '',
            message: '',
            supportFiles: null,
            sending: false,
            responseMessage: null,
            fileMessage: null
        }

        this.dismissMessage = this.dismissMessage.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleFileUpload = this.handleFileUpload.bind(this);
        this.emailValid = this.emailValid.bind(this);
        this.sendSupport = this.sendSupport.bind(this);
        this.dismissFileMessage = this.dismissFileMessage.bind(this);
        this.clearFiles = this.clearFiles.bind(this);
    }

    sendSupport(e){
        const errorMessage = <Message
            onDismiss={this.dismissMessage}
            content={<span>There was an error storing your support request. Please <a href="mailto:support@apispreadsheets.com?Subject=Support">email us at support@apispreadsheets.com</a> directly.</span>}
            negative
        />

        this.setState({
            sending: true
        })

        let formData = new FormData();

        // Add Files to Form Data
        if (this.state.supportFiles !== null){
            for (let i=0; i<this.state.supportFiles.length; i++){
                formData.append("file" + i.toString(), this.state.supportFiles[i]);
            }
        }

        // Add form info
        formData.append("email", this.state.email)
        formData.append("subject", this.state.subject);
        formData.append("message", this.state.message);
        formData.append("name", this.state.name);
        formData.append("userEmail", localStorage.getItem("email"));

        fetch(API_Root + 'api/store-love-spreadsheets-support/', {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
            },
            body: formData
        }).then((res) => {
            if (res.status === 201){
                this.setState({
                    sending: false,
                    responseMessage: <Message
                        onDismiss={this.dismissMessage}
                        content={<span>Thanks for sending your support request. We will be reaching out to you shortly.</span>}
                        positive
                    />
                })
            }
            else{
                this.setState({
                    sending: false,
                    responseMessage: errorMessage
                })
            }
        }).catch(err => {
            this.setState({
                sending: false,
                responseMessage: errorMessage
            })
        })
    }

    clearFiles(e){
        this.setState({
            supportFiles: null,
            fileMessage: null
        })
    }


    handleFileUpload(e){
        const files = e.target.files;

        if (files.length > 0){
            let fileSize = 0;

            for (let i=0; i<files.length; i++){
                fileSize += bytesToMB(files[i].size);
            }

            if (this.state.supportFiles !== null){
                for (let j=0; j<this.state.supportFiles.length; j++){
                    fileSize += bytesToMB(this.state.supportFiles[j].size);
                }
            }

            if (fileSize <= 5){
                this.setState({
                    supportFiles: files,
                })
            }
            else{
                const fileMessage = <Message
                    onDismiss={this.dismissFileMessage}
                    content="You cannot upload more than 5MB Total"
                    negative
                />;

                this.setState({
                    fileMessage: fileMessage
                })
            }


        }
    }

    handleInputChange(e){
        const name = e.target.name;
        const value = e.target.value;

        if (name === "subject"){
            if (value.length <= 75){
                this.setState({
                    [name]: value
                })
            }
        }
        else if (name === "message"){
            if (value.length <= 5000){
                this.setState({
                    [name]: value
                })
            }
        }
        else{
            this.setState({
                [name]: value
            })
        }
    }

    dismissMessage(e){
        this.setState({
            responseMessage: null
        })
    }

    dismissFileMessage(e){
        this.setState({
            fileMessage: null
        })
    }

    emailValid(){
        if (this.state.email !== null){
            if (this.state.email.trim() !== ""){
                return emailIsValid(this.state.email)
            }
            else{
                return true
            }
        }
        else{
            return true
        }

    }

    render() {
        const inputFileStyle = {
            width: '0.1px',
            height: '0.1px',
            opacity: '0',
            overflow: 'hidden',
            position: 'absolute',
            zIndex: '-1'
        };

        const emailError = !this.emailValid() ? <small className="inputError">Enter a valid email</small> : null;
        const subjectCharLeft = 75 - this.state.subject.length;
        const subjectCharMessage = subjectCharLeft.toString() + " character" + (subjectCharLeft !== 1 ? "s" : '') + " left";

        const detailCharLeft = 5000 - this.state.message.length;
        const detailCharMessage = detailCharLeft.toString() + " character" + (subjectCharLeft !== 1 ? "s" : '') + " left";

        let filesUploadedMessage = null;

        if (this.state.supportFiles !== null){
            let fileNames = [];

            for (let i=0; i<this.state.supportFiles.length; i++){
                fileNames.push(this.state.supportFiles[i].name);
            }

            filesUploadedMessage =<Message
                header={<span><b>Files Selected</b><button id="supportFormClearAllButton" onClick={this.clearFiles}>Clear All</button></span>}
                list={fileNames}
                positive
            />;
        }

        return (
            <div id="supportForm">
                {this.state.responseMessage}
                <span className="supportFormLabel">Your Name</span>
                <Input icon="user"
                       iconPosition="left"
                       name='name'
                       onChange={this.handleInputChange}
                       value={this.state.name} fluid />
                <br/>
                <span><span className="supportFormLabel">Preferred Email</span><small className="requiredLabel">Required</small></span>
                <Input  icon="at"
                        iconPosition='left'
                        type="email"
                        name="email"
                        onChange={this.handleInputChange}
                        error={!this.emailValid()}
                        value={this.state.email} fluid/>
                {emailError}
                <br/>
                <span><span className="supportFormLabel">Briefly Describe Your Issue</span><small className="requiredLabel">Required</small><small id="subjectCharLeft"><i>{"   " + subjectCharMessage}</i></small></span>
                <Input icon="bug"
                       iconPosition='left'
                       name="subject"
                       onChange={this.handleInputChange}
                       value={this.state.subject}
                       fluid />

                <br/>
                <span><span className="supportFormLabel">Provide As Much Detail As Possible</span><small id="subjectCharLeft"><i>{"  " + detailCharMessage}</i></small></span>
                <Form>
                    <TextArea
                        onChange={this.handleInputChange}
                        name="message"
                        value={this.state.message}
                        rows={6} />

                </Form>
                <br/>
                <input
                    type="file"
                    onChange={this.handleFileUpload}
                    style={inputFileStyle}
                    multiple={true}
                    name="inputFileUpload"
                    id="inputFileUpload"
                    accept=".txt, .csv, .xlsx, .png, .jpeg, .jpg, .doc, .docx, .pdf"
                />
                <span>
                    <span className="supportFormLabel">Upload Supporting Files</span>
                    <small id="subjectCharLeft">    5 MB Max</small>
                    <Popup fluid trigger={<Icon style={{paddingLeft: '4px', paddingTop: '2px'}} name="info circle"/>}>
                        <span><b>Following extensions valid</b></span>
                        <ul><li>.txt</li><li>.csv</li><li>.xlsx</li><li>.png</li><li>.jpeg</li><li>.jpg</li><li>.doc</li><li>.docx</li><li>.pdf</li></ul>
                    </Popup>
                </span>
                <br/>
                <label htmlFor="inputFileUpload">
                    <div className="buttonRaw" id="supportUploadFiles">
                        <Icon name="upload"/> Upload Files
                    </div>
                </label>
                {filesUploadedMessage}
                {this.state.fileMessage}
                <br/><br/>
                <Button content="Get Help"
                        icon="paper plane outline"
                        color="green"
                        onClick={this.sendSupport}
                        fluid
                        size="large"
                        loading={this.state.sending}
                        disabled={!this.emailValid() || this.state.subject.trim().length === 0}/>
                <br/><br/>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(SupportForm)