import React, {Component} from 'react'
import LoginForm from './LoginForm'

class LoginIndex extends Component {
    render() {
        return (
            <div className="row" style={{backgroundColor: '#7fd3fd', minHeight: '100vh'}}>
                <div className="col-sm-4" />
                <div className="col-sm-4">
                    <br/><br/><br/><br/>
                    <LoginForm/>
                </div>
                <div className="col-sm-4" />
            </div>
        )
    }
}

export default LoginIndex