import React, {Component} from 'react'
import AuthUserContext from '../Authentication/AuthUserContext';

class AuthComponent extends Component {
    render() {
        const componentWhenUserAuth = this.props.authComponent;
        const componentWhenUserNonAuth = this.props.nonAuthComponent;

        return (
            <div>
                <AuthUserContext.Consumer>
                    {  authUser => authUser
                        ? componentWhenUserAuth
                        : componentWhenUserNonAuth

                    }
                </AuthUserContext.Consumer>
            </div>
        )
    }
}

export default AuthComponent