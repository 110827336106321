import React, {Component} from 'react'
import { Accordion, Icon } from 'semantic-ui-react'
import DownloadReceipts from "./DownloadReceipts"

class DownloadReceiptsAccordion extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeIndex: -1
        }
    }

    handleClick = (e, titleProps) => {
        const { index } = titleProps

        const newIndex = this.state.activeIndex === index ? -1 : index

        this.setState({
            activeIndex: newIndex
        })
    }

    render() {
        return (
            <div>
                <Accordion styled fluid>
                    <Accordion.Title
                        active={this.state.activeIndex === 0}
                        index={0}
                        onClick={this.handleClick}
                    >

                        <h5 className="thinHeading text-muted">
                            <Icon name='dropdown' />
                            Receipts
                        </h5>
                    </Accordion.Title>
                    <Accordion.Content active={this.state.activeIndex === 0}>
                        <DownloadReceipts />
                    </Accordion.Content>
                </Accordion>
            </div>
        )
    }
}

export default DownloadReceiptsAccordion