import firebase from 'firebase/app';
import 'firebase/auth';

// Initialize Firebase
const config = {
    apiKey: "AIzaSyCDh-EBnkEP1NyTSA05dwLNdW2hf-Fwn_Q",
    authDomain: "merge-spreadshets.firebaseapp.com",
    databaseURL: "https://merge-spreadshets.firebaseio.com",
    projectId: "merge-spreadshets",
    storageBucket: "merge-spreadshets.appspot.com",
    messagingSenderId: "844503265298",
    appId: "1:844503265298:web:16a3174a27dc64c9"
};

if (!firebase.apps.length) {
    firebase.initializeApp(config);
}

const auth = firebase.auth();

export {
    auth,
};
