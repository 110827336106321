import React, {Component} from 'react'
import ModalApp from '../Modal'
import { Form, TextArea } from 'semantic-ui-react'
import FeedbackSubmitButton from './FeedbackSubmitButton'
import Rating from 'react-rating'
// const Rating = require('react-rating');
import CustomRating from "./CustomRating";

class FeedbackModal extends Component {
    constructor(props){
        super(props);

        this.state = {
            feedback: '',
            rating: 0
        };

        this.onChange = this.onChange.bind(this);
        this.updateRating = this.updateRating.bind(this);
    }

    onChange(e){
        this.setState({
            feedback: e.target.value
        })
    }

    updateRating(rating){
        this.setState({
            rating: rating
        })
    }

    clearStates = () => {
        this.setState({ feedback: '', rating: 0 })
    }

    render() {
        const modalBody =
            <div className="row">
                <div className="col-sm-1" />
                <div className="col-sm-10">
                    <h3 className="thinHeading">How did you find your Split Spreadsheets experience?</h3>
                    <div style={{color: 'purple'}}>
                        <CustomRating
                            rating={this.state.rating}
                            updateRating={this.updateRating}
                        />
                    </div>
                    <i>Rate us from 1-5 stars</i>
                    <br/><br/>
                    <Form>
                        <TextArea value={this.state.feedback} placeholder="What I liked/disliked about Split Spreadsheets..." onChange={this.onChange}/>
                    </Form>
                    <br/><br/>
                    <div style={{float: 'left'}}>
                        <FeedbackSubmitButton
                            rating={this.state.rating}
                            clearStates={this.clearStates}
                            feedback={this.state.feedback}/>
                    </div>
                    <br/><br/><br/><br/>
                </div>
                <div className="col-sm-1" />
            </div>;

        return (
            <div>
                <ModalApp name="feedbackModal"
                          header={null}
                          body={modalBody}
                          size="small"
                />
            </div>
        )
    }
}

export default FeedbackModal