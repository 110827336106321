import React, {Component} from 'react'
import ModalApp from '../../../SharedComponents/Modal'
import SignUpModalBody from './SignUpModalBody'

class SignUpModal extends Component {
    render() {
        return (
            <ModalApp
                name="signUpModal"
                bodyColor="rgb(207, 92, 207)"
                header={null}
                body={<SignUpModalBody/>}
                size="large"
            />
        )
    }
}

export default SignUpModal