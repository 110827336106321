import React, {Component} from 'react'
import { connect } from 'react-redux'
import UploadIndicator from "./Indicators/UploadIndicator";
import ReadingIndicator from './Indicators/ReadingIndicator'
import constants from '../../Constants/constants'
import {modifyStatus} from "../../Actions/ModifyStatus";
import FileDrop from 'react-file-drop';
import {modifyFilePK} from "../../Actions/ModifyFilePK";
import { isUserPro } from "../../Constants/isUserPro";
import API_Root from "../../Constants/API_Root"
import {modifyFileInformation} from "../../Actions/ModifyFileInformation";
import bytesToMB from "../../Constants/bytesToMB"
import {modifyEditOptions} from "../../Actions/ModifyEditOptions";
import {togglePro} from "../../Actions/TogglePro";

class UploadFilesIndex extends Component {
    constructor(props) {
        super(props);

        this.state = {
            errorMessage: null
        };

        this.handleFileUpload = this.handleFileUpload.bind(this);
        this.getStatusAndResults = this.getStatusAndResults.bind(this);
    }

    handleFileUpload(droppedFiles, e){
        const files = droppedFiles === null ? e.target.files : droppedFiles;
        console.log(files)
        if (files.length > 0){
            let fileNames = [];
            let fileSize = 0;

            const formData = new FormData();
            for (let i=0; i<files.length; i++){
                formData.append("file" + i.toString(), files[i]);
                fileNames.push(files[i].name)
                fileSize += bytesToMB(files[i].size)
            }

            formData.append("email", localStorage.getItem("email"));
            formData.append("token", localStorage.getItem("token"));

            //
            //
            // UPPER LIMIT DOES NOT EXIST CURRENTLY
            //
            //
            this.props.modifyStatus("reading");

            fetch(API_Root + 'api/save-split-files/', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                },
                body: formData
            }).then(res => res.json())
                .then(data => {
                    if (!data.error){
                        // Modify the File Primary Keys
                        let filePK = data.filePK;

                        this.props.modifyStatus("saved");
                        this.props.modifyFilePK(filePK);

                        // Set True or False if User can work with this size of files
                        this.props.togglePro(isUserPro(data.pro));

                        // Open up WebSocket when files are saved
                        this.getStatusAndResults(data.filePK, fileSize);
                    }
                    else{
                        alert("There was an error reading the file. Please refresh and try again")
                    }
                }).catch((err) => {alert("There was an error reading the file. Please refresh and try again")});
        }
    }

    getStatusAndResults(filePK, fileSize){
        // Sample 5 rows to view
        // Sheet Read (if Excel)
        // Column Names

        // Open up WebSockets
        const websocketRoute = API_Root.substring(0, 5) === "https" ? API_Root.replace("https", "wss") : API_Root.replace("http", "ws");
        const socket = new WebSocket(websocketRoute + "ws/api/read-split-file/");

        const props = this.props;

        // Send the PKs and if any column names
        socket.onopen = (event) => {
            socket.send(JSON.stringify({
                filePK: filePK,
                size: fileSize
            }));
        };

        socket.onmessage = (event) => {
            const reply = JSON.parse(event.data);
            const status = reply['status'];

            props.modifyStatus(status);

            if (status === "read"){
                let fileInformation = reply.fileInformation;
                props.modifyFileInformation(fileInformation)
                props.modifyEditOptions({
                    header: true,
                    startRow: "",
                    startRowError: false,
                    delimiter: fileInformation.delimiter
                })

                socket.close()
            }
            else if (status === "error"){
                socket.close()
                alert("There was a problem reading your file. Please try again")
            }
        };
    }


    render() {
        // Hides the Input Button
        const inputFileStyle = {
            width: '0.1px',
            height: '0.1px',
            opacity: '0',
            overflow: 'hidden',
            position: 'absolute',
            zIndex: '-1'
        };

        // Valid extensions for the file upload
        const acceptExtensions = constants.extensions.join(",");

        // Components for what status the job is in
        const indicators = {
            upload: <UploadIndicator />,
            reading: <ReadingIndicator percentage={0} />,
            saved: <ReadingIndicator percentage={45} />,
            read: <UploadIndicator />
        };

        return (
            <div>
                <div className="row">
                    <input
                        type="file"
                        name="inputFileUpload"
                        id="inputFileUpload"
                        className="form-control-file"
                        onChange={(e) => this.handleFileUpload(null, e)}
                        style={inputFileStyle}
                        multiple={false}
                        accept={acceptExtensions}
                    />
                    <div className="col-sm-1" />
                    <div className="col-sm-10">
                        <div className="uploadOuterSection">
                            <label className="uploadInnerSection" htmlFor="inputFileUpload">
                                <FileDrop draggingOverTargetClassName="lightgray" onDrop={this.handleFileUpload}>
                                    {indicators[this.props.status]}
                                </FileDrop>
                            </label>
                        </div>
                        <small><i>You can select files with any of these extensions: .xls, .xlt, .xlm, .xlsx, .xlsm, .xltx, .xltm, .xlsb, .csv, .txt</i></small>
                    </div>
                    <div className="col-sm-1" />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    filePK: state.mainState.filePK,
    fileInformation: state.mainState.fileInformation,
    status: state.mainState.status,
});

const mapActionsToProps = {
    modifyStatus: modifyStatus,
    modifyFilePK: modifyFilePK,
    modifyFileInformation: modifyFileInformation,
    modifyEditOptions: modifyEditOptions,
    togglePro: togglePro
};

export default connect(mapStateToProps, mapActionsToProps)(UploadFilesIndex)