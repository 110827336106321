import React, {Component} from 'react'
import SignOutButton from '../../Authentication/UserPages/SignOut/SignOutButton'
import { Menu, Image, Icon } from 'semantic-ui-react'
import images from "../../Constants/Images";
import NavbarItem from './NavbarItem'
import { push } from 'react-router-redux'
import {connect} from "react-redux";
import {BrowserView, MobileView, isMobile} from 'react-device-detect'

class NavbarAuth extends Component {
    constructor(props){
        super(props);

        this.state = {
            dashboard: false,
            urlEnd: window.location.pathname.replace("/", ""),
            mobileView: false
        }

        this.goToURL = this.goToURL.bind(this);
        this.changeURLEnd = this.changeURLEnd.bind(this);
        this.toggle = this.toggle.bind(this);
    }

    goToURL(e){
        this.setState({
            urlEnd: ''
        })
        e.preventDefault();
        this.props.navigateTo("/")
    }

    changeURLEnd(changedURLEnd){
        this.setState({
            urlEnd: changedURLEnd
        })
    }

    toggle(e, state){
        this.setState({
            mobileView: state
        })
    }

    render() {
        let icon;
        let displayStyle = null;

        if (isMobile){
            if (this.state.mobileView){
                icon = <Icon name="close" style={{marginTop: '5px', cursor: 'pointer'}} onClick={(e) => this.toggle(e, false)}/>
                displayStyle = null;
            }
            else{
                icon = <Icon style={{cursor: 'pointer'}} name="bars" onClick={(e) => this.toggle(e, true)}/>
                displayStyle = {display: 'none'}
            }
        }

        return (
            <div>
                <MobileView>
                    <div style={{marginTop: '7px', paddingLeft: '5px', paddingRight: '5px'}}>
                        <img style={{cursor: 'pointer', height: '50%', width: '50%'}} src={images.logoNav} onClick={this.goToURL} alt="logo" />
                        <span style={{float: 'right'}}>{icon}</span>
                    </div>
                </MobileView>
                <Menu secondary stackable style={displayStyle}>
                    <Menu.Menu position="left">
                        <BrowserView>
                            <Menu.Item>
                                <div style={{marginTop: '5px', marginLeft: '10px'}}>
                                    <Image style={{cursor: 'pointer'}} src={images.logoNav} onClick={this.goToURL} size="small"/>
                                </div>
                            </Menu.Item>
                        </BrowserView>
                    </Menu.Menu>
                    <Menu.Menu position="right">
                        <Menu.Item>
                            <NavbarItem to="FAQs" name="FAQs" color="black" onClick={this.goToURL}/>
                        </Menu.Item>
                        <Menu.Item>
                            <NavbarItem to="profileModal" name={localStorage.getItem("email")} color="gray" modal auth/>
                        </Menu.Item>
                        <Menu.Item>
                            <NavbarItem to="supportModal" name="Support" color="gray" modal auth/>
                        </Menu.Item>
                        <Menu.Item>
                            <NavbarItem to="feedbackModal" name="Feedback" color="gray" modal auth/>
                        </Menu.Item>
                        <Menu.Item>
                            <SignOutButton style={{marginTop: '5px'}} />
                        </Menu.Item>
                    </Menu.Menu>
                </Menu>
            </div>
        )
    }
}


const mapStateToProps = (state) => ({
});

const mapActionsToProps = {
    navigateTo: push
};

export default connect(mapStateToProps, mapActionsToProps)(NavbarAuth)