import React, {Component} from 'react'
import FAQ from './FAQ'
import { Header, Icon, Segment, Divider } from 'semantic-ui-react'

class FAQBlock extends Component {
    render() {
        const FAQs = this.props.FAQs.map((x, idx) =>
            "img" in x ?
            <FAQ key={idx} question={x.question} answer={x.answer} img={x.img}/>
                :
            <FAQ key={idx} question={x.question} answer={x.answer} />
        );

        return (
            <div style={{paddingTop: '10px'}}>
                {/*<h1 className="thinHeading">*/}
                    {/*{this.props.title}*/}
                {/*</h1>*/}
                {/*<Segment raised>*/}
                    <Header as='h3'>
                        <Icon name={this.props.icon} />
                        <Header.Content>
                            {this.props.title}
                        </Header.Content>
                    </Header>
                    <Divider/>
                    {FAQs}
                {/*</Segment>*/}
            </div>
        )
    }
}

export default FAQBlock