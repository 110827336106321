import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Button } from 'semantic-ui-react'
import {resetFileState} from "../Actions/ResetFileState";
import isObjectEmpty from "../Constants/isObjectEmpty";

class ResetButton extends Component {
    constructor(props) {
        super(props);
    }

    resetStates = (e) => {
        this.props.resetFileState()
    }

    render() {
        if (isObjectEmpty(this.props.fileInformation)){
            return (
                null
            )
        } else {
            return (
                <Button icon="redo" content="Reset" basic color="red" style={{marginLeft: "1%"}} onClick={this.resetStates}/>
            )
        }
    }
}

const mapStateToProps = (state) => ({
    fileInformation: state.mainState.fileInformation
})

const mapActionsToProps = {
    resetFileState: resetFileState
}

export default connect(mapStateToProps, mapActionsToProps)(ResetButton)