import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Card, Button, Icon } from 'semantic-ui-react';
import {toggleModal} from "../../Actions/ToggleModal";
import { push } from 'react-router-redux'
import { BrowserView, MobileView } from "react-device-detect";

class PricingCard extends Component {
    constructor(props) {
        super(props);

        this.goHome = this.goHome.bind(this);
        this.openSignUp = this.openSignUp.bind(this);
        this.contactUs = this.contactUs.bind(this);
    }

    goHome(e){
        this.props.navigateTo("/")
    }

    openSignUp(e){
        this.props.toggleModal("signUpModal");
    }

    contactUs(e){
        this.contactLink.click()
    }

    render() {
        const desc = this.props.desc.map((x, idx) => <li key={idx}>{x}</li>);

        let clickFunction = this.openSignUp;
        let buttonColor = "purple";
        let buttonText = <span>Upgrade Now <Icon name="arrow right" /></span>;
        let url = null;

        if (this.props.header === "FREE"){
            clickFunction = this.goHome;
            buttonColor = "blue"
            buttonText = "Split Now"
        }
        else if (this.props.header === "CUSTOM"){
            clickFunction = this.contactUs;
            buttonColor = "orange";
            buttonText = "Contact Us";
            url = "mailto:info@mergespreadsheets.com?Subject=Custom%20Pricing%20Merge%20Spreadsheets"
        }

        return (
            <Card raised style={{float: this.props.float}}>
                <Card.Content>
                    <Card.Header style={{textAlign: 'center'}}>{this.props.header}</Card.Header>
                    <br/>
                    <Card.Meta style={{textAlign: 'center'}}><span style={{fontSize: '125%', color: '#708090'}}>{this.props.cost}</span></Card.Meta>
                    <br/>
                    <Card.Description>
                        <ul className="desc">
                            {desc}
                        </ul>
                    </Card.Description>
                    <Card.Content extra style={{backgroundColor: 'lightgray', textAlign: 'center'}}>
                        <br/>
                        <BrowserView>
                            <Button onClick={clickFunction} color={buttonColor}>{buttonText}</Button>
                        </BrowserView>
                        <MobileView>
                            <Button color="purple" basic icon="paper plane" content="Email yourself" onClick={this.goHome}/>
                        </MobileView>
                        <br/>
                        <a
                            style={{display: 'none'}}
                            ref={contactLink => this.contactLink = contactLink}
                            href={url}
                        >Contact Us Email</a>
                    </Card.Content>
                </Card.Content>
            </Card>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {
    toggleModal: toggleModal,
    navigateTo: push
}

export default connect(mapStateToProps, mapActionsToProps)(PricingCard)