import React, {Component} from 'react'
import ModalApp from '../../../SharedComponents/Modal'
import { Segment } from 'semantic-ui-react'
import PasswordForget from "./PasswordForget";

class ForgetPasswordModal extends Component {
    render() {
        const body =
            <div className="row">
                <div className="col-sm-2" />
                <div className="col-sm-8">
                    <br/><br/>
                    <Segment raised>
                        <PasswordForget/>
                    </Segment>
                    <br/><br/><br/>
                </div>
                <div className="col-sm-2" />
            </div>;

        return (
            <ModalApp name="forgetPasswordModal"
                      header={null}
                      body={body}
                      size="medium"
                      bodyColor="#7fd3fd"
            />
        )
    }
}


export default ForgetPasswordModal