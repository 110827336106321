import React, {Component} from 'react'
import constants from '../Constants/constants'

class TermsAndConditions extends Component {
    render() {
        const product = constants.productName;

        return (
            <div>
                <br/><br/><br/>
                <div className="row">
                    <div className="col-sm-1"> </div>
                    <div className="col-sm-10">
                        <div className="row">
                            <div className="col-sm-12">
                                <h1>Terms and Conditions</h1>
                            </div>
                        </div>
                        <br/>
                        <div className="row">
                            <div className="col-sm-12">
                                <h3>1. Acceptance of Terms</h3>
                                <p>By using <span>{product}</span>, you are entering into an agreement with Woyera, Inc. regarding these terms and services.
                                    You also may be subject to additional guidelines, rules, policies or other terms of service posted on portions of the site.
                                    The Site and Services are only available to individuals who (a) are at least 13 years old and (b) either 18 years old or possess legal parental or guardian consent.
                                    <span>{product}</span> may, in its sole discretion, modify or revise these Terms of Service at any time, and you agree to be bound by such modifications or revisions.
                                    We will notify you by making the revised version available on this web page, and an updated revision date will indicate that changes have been made.</p>
                            </div>
                        </div>
                        <br/>
                        <div className="row">
                            <div className="col-sm-12">
                                <h3>2. Registration & Passwords</h3>
                                <p>To use some of the features on the Site or Services, including paying and submitting the files for cleaning, you may be required to create an account.
                                    Any required fields that are submitted as part of the registration process must be accurate and complete.
                                    You are solely responsible for activity that occurs on your account.
                                    You should keep your password confidential and agree to notify us immediately of any unauthorized use of your account.
                                    We reserve the right to close your account at any time for any or no reason.</p>
                            </div>
                        </div>
                        <br/>
                        <div className="row">
                            <div className="col-sm-12">
                                <h3>3. Additional Terms & Guidelines</h3>
                                <p>When using the Site or Services, you will be subject to any additional posted guidelines or rules applicable to specific services and features which may be posted from time to time (the “Guidelines”).
                                    All such Guidelines are hereby incorporated by reference into these Terms.
                                    Such additional terms and guidelines include third-party licenses that may apply to other data on <span>{product}</span>(below).</p>
                            </div>
                        </div>
                        <br/>
                        <div className="row">
                            <div className="col-sm-12">
                                <h3>4. Use of Data and Content</h3>
                                <p>By using the Site you represent and warrant that any User Content posted by you does not contain material or links to material that infringes upon a third party’s
                                    intellectual property or proprietary rights including, without limitation, copyright, patent, trademark, trade secrets, or rights of publicity or privacy.
                                    You agree to accept full liability and responsibility for all User Content submitted by you or on your behalf for posting on the Site.</p>
                            </div>
                        </div>
                        <br/>
                        <div className="row">
                            <div className="col-sm-12">
                                <h3>5. Prohibited uses</h3>
                                <p>By using the Site or Services, you agree not to:</p>
                                <ul>
                                    <li>Upload any material that:</li>
                                    <ul>
                                        <li>infringes on any copyright or other intellectual property right of any other person or entity</li>
                                        <li>is illegal, defamatory, libelous, deceptive, fraudulent, obscene or offensive;</li>
                                        <li>contains software viruses or other code designed to disrupt computing services;</li>
                                        <li>as uploaded violates a valid third-party agreement;</li>
                                    </ul>
                                </ul>
                                <ul>
                                    <li>take any action that interferes or may interfere, based on our sole discretion, with operations of the Site or Services, including but not limited to</li>
                                    <ul>
                                        <li>placing an unreasonable load on <span>{product}</span> infrastructure;</li>
                                        <li>circumventing security features used to prevent or restrict access to or use of the Site or Services;</li>
                                        <li>creating user accounts by automated means;</li>
                                        <li>impersonating any person or entity;</li>
                                        <li>using the Site or Services for any illegal purpose;</li>
                                        <li>using any embeddable or syndicatable features of the Site or Services in a way that alters or circumvents how <span>{product}</span> designed such features to be used;</li>
                                        <li>selling, reselling, redistributing, or sublicensing the Services or otherwise charging third parties for access to the Site or Services. For the avoidance of doubt, these commercial restrictions do not generally apply to data and content accessed via the Services, but rather to the use of the Services themselves — except in certain circumstances noted above (see “Your Contribution and Use of Data and Content”).</li>
                                    </ul>
                                </ul>
                            </div>
                        </div>
                        <br/>
                        <div className="row">
                            <div className="col-sm-12">
                                <h3>6. Privacy Policy</h3>
                                <p>We respect the privacy of our users. These Terms of Service incorporate the terms of the <span>{product}</span> Privacy Policy.</p>
                            </div>
                        </div>
                        <br/>
                        <div className="row">
                            <div className="col-sm-12">
                                <h3>7. Service Modifications and Availability</h3>
                                <p><span>{product}</span> reserves the right, at its sole discretion, to modify or discontinue the Site or Services, at any time, for any or no reason, and without notice or liability.
                                    We also schedule system downtime from time to time, and unplanned system outages may occur.
                                    We shall have no liability whatsoever for the resulting unavailability of the Site or Services or for any loss of data or transactions caused by planned or unplanned system outages or the resultant delay,
                                    misdelivery, nondelivery of information caused by such system outages, or any third party acts or any other outages of web host providers or the Internet infrastructure and network external to the Site or Services.</p>
                            </div>
                        </div>
                        <br/>
                        <div className="row">
                            <div className="col-sm-12">
                                <h3>8. Proprietary Rights and Trademark</h3>
                                <p>The Website. The visual interfaces, graphics, design, compilation, code, products, services, and all other proprietary elements of the Site and Services provided by Woyera (the “Materials”) are protected by United States copyright,
                                    trade dress, patent, and trademark laws, international conventions, and all other relevant intellectual property and proprietary rights, and applicable laws. Except as otherwise designated here and on the Site or Services, all Materials
                                    contained on the Site or Services are the property of Woyera or its subsidiaries or affiliated companies and/or third-party licensors.
                                    Except as expressly authorized by Woyera, you agree not to sell, sell access to, distribute, copy, modify, publicly perform or display, transmit, publish, edit, adapt, create derivative works from, or otherwise make unauthorized use of the proprietary elements of the Site and Services.</p>
                            </div>
                        </div>
                        <br/>
                        <div className="row">
                            <div className="col-sm-12">
                                <h3>9. Disclaimers; No Warranties</h3>
                                <p>Woyera IS NOT RESPONSIBLE FOR ANY INACCURACIES IN ANY MATERIALS OR DATA ACCESSED VIA THE SITE OR SERVICES REGARDLESS OF THE CAUSE: WHETHER ATTRIBUTABLE TO USERS OR TO Woyera’S SYSTEMS. WE ARE NOT RESPONSIBLE FOR THE CONDUCT, WHETHER ONLINE OR OFFLINE, OF ANY USER OF SITE OR SERVICES. WE ASSUME NO RESPONSIBILITY FOR ANY ERROR, OMISSION, INTERRUPTION, DELETION, DEFECT, DELAY IN OPERATION OR TRANSMISSION, COMMUNICATIONS LINE FAILURE, THEFT OR DESTRUCTION OR UNAUTHORIZED ACCESS TO, OR ALTERATION OF, ANY COMMUNICATION WITH OTHER USERS. WE ARE NOT RESPONSIBLE FOR ANY PROBLEMS OR TECHNICAL MALFUNCTION OF ANY HARDWARE AND SOFTWARE DUE TO TECHNICAL PROBLEMS ON THE INTERNET OR AT THE SITE OR COMBINATION THEREOF, INCLUDING ANY INJURY OR DAMAGE TO USERS OR TO ANY PERSON’S COMPUTER RELATED TO OR RESULTING FROM PARTICIPATION OR DOWNLOADING MATERIALS IN CONNECTION WITH SITE OR SERVICES. UNDER NO CIRCUMSTANCES SHALL WE BE RESPONSIBLE FOR ANY LOSS OR DAMAGE, INCLUDING PERSONAL INJURY OR DEATH, RESULTING FROM USE OF SITE OR SERVICES OR FROM ANY CONTENT POSTED ON THE SITE OR TRANSMITTED TO USERS, OR ANY INTERACTIONS BETWEEN USERS OF SITE OR SERVICES, WHETHER ONLINE OR OFFLINE. SITE OR SERVICES ARE PROVIDED AS-IS AND AS AVAILABLE. WE EXPRESSLY DISCLAIM ANY WARRANTIES AND CONDITIONS OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING THE WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, QUIET ENJOYMENT, ACCURACY, OR NON-INFRINGEMENT. WE MAKE NO WARRANTY THAT: (A) THE SERVICES WILL MEET YOUR REQUIREMENTS; (B) THE SERVICES WILL BE AVAILABLE ON AN UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE BASIS; OR (C) THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE SERVICES WILL BE ACCURATE OR RELIABLE.</p>
                            </div>
                        </div>
                        <br/>
                        <div className="row">
                            <div className="col-sm-12">
                                <h3>10. Payment</h3>
                                <p>
                                   By executing this agreement, the user enters into an agreement with Woyera, Inc, in which the user agrees to pay $20 per file and $5MB for every MB greater than 2MB.
                                    The user will be required to pay at the time the files are uploaded. When the Approval step occurs, the User is entitled to get a refund if they do not approve the cleaning sample.
                                    If the user does not approve the cleaning sample, the transaction is finished and the user is not entitled to any refund.
                                    Woyera, Inc. reserves to right to change prices at any time with notice to the users, and service of an updated form of these terms and conditions for the user to agree to in order to continue using the services provided by Woyera, Inc.
                                    Futher, Woyera Inc. maintains the right to suspend or delete accounts which are misused at any time and for any reason in violation of these Terms & Conditions.
                                    A user whose account is suspended or deleted for misuse will not be entitled to a refund regardless of when their last payment was submitted.
                                    These terms and conditions may be subject to change due to promotional or other offers.  Additional terms will be provided with the offer.
                                </p>
                            </div>
                        </div>
                        <br/>
                        <div className="row">
                            <div className="col-sm-12">
                                <h3>11. Limitations of Liability</h3>
                                <p>IN NO EVENT SHALL WE BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY LOST PROFIT OR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES ARISING FROM YOUR USE OF THE SITE OR SERVICES, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR LIABILITY TO YOU FOR ANY DAMAGES ARISING FROM OR RELATED TO YOUR USE OF THE SITE OR SERVICES (FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION), WILL AT ALL TIMES BE LIMITED TO US$100. YOU UNDERSTAND AND AGREE THAT THE DOWNLOAD AND UPLOAD OF ANY MATERIAL THROUGH THIS SITE IS DONE AT YOUR DISCRETION AND RISK AND THAT YOU WILL BE SOLELY RESPONSIBLE FOR ANY LOSS OR DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT MAY RESULT IN THE DOWNLOAD OR UPLOAD OF ANY MATERIAL. Woyera NEITHER ASSUMES, NOR DOES IT AUTHORIZE ANY PERSON OR ENTITY TO ASSUME ON ITS BEHALF, ANY OTHER LIABILITY IN CONNECTION WITH THE PROVISION OF THE SITE OR SERVICES. THE LIMITATIONS OF LIABILITY PROVIDED IN THESE TERMS OF SERVICE INURE TO THE BENEFIT OF Woyera, SHAREHOLDERS, AFFILIATES AND TO ALL OF OUR RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES, ATTORNEYS AND AGENTS AND, IN EACH CASE, LIABILITY IS LIMITED TO THE FULLEST EXTENT PERMITTED BY LAW. SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OF CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU AND YOU MAY ALSO HAVE OTHER LEGAL RIGHTS THAT VARY FROM JURISDICTION TO JURISDICTION.</p>
                            </div>
                        </div>
                        <br/>
                        <div className="row">
                            <div className="col-sm-12">
                                <h3>12. Indemnity</h3>
                                <p>You agree to indemnify, defend and hold harmless Woyera, shareholders, subsidiaries, affiliates, officers, directors, employees, agents and advisors from and against any and all claims, demands, actions, costs, liabilities, losses and damages of any kind (including attorneys’ fees) resulting from your use of the Site or Services, including APIs, provided hereunder by Woyera, any data you provide, any violations by you of any applicable laws or regulations, and any breaches by you of these Terms of Service. Any disputes will be held through mediation.</p>
                            </div>
                        </div>
                        <br/>
                        <div className="row">
                            <div className="col-sm-12">
                                <h3>13. Severability</h3>
                                <p>When using <span>{product}</span>, you will be subject to any additional posted guidelines or rules applicable to specific services and features which may be posted from time to time (the “Guidelines”). All such Guidelines are hereby incorporated by reference into these Terms.</p>
                            </div>
                        </div>
                        <br/>
                        <div className="row">
                            <div className="col-sm-12">
                                <h3>14. Waiver</h3>
                                <p>The failure of Woyera to exercise or enforce any right or provision of these Terms will not constitute a waiver of such right or provision. Any waiver of any provision of these Terms will be effective only if in writing and signed by Woyera.</p>
                            </div>
                        </div>
                        <br/>
                        <div className="row">
                            <div className="col-sm-12">
                                <h3>15. Relationship of Parties</h3>
                                <p>No agency, partnership, joint venture, employee-employer or franchiser-franchisee relationship is intended or created by these Terms of Service, and these Terms of Service are solely between you and Woyera and are not intended to be for the benefit of any third party. If there is a dispute between participants on this Site, or between users and any third party, you understand and agree that Woyera is under no obligation to become involved. In the event that you have a dispute with one or more users or third parties, you hereby release Woyera, its officers, employees, agents and successors in rights from claims, demands and damages (actual and consequential) of every kind or nature, known or unknown, suspected and unsuspected, disclosed and undisclosed, arising out of or in any way related to such disputes and/or services.</p>
                            </div>
                        </div>
                        <br/>
                        <div className="row">
                            <div className="col-sm-12">
                                <h3>16. Governing Law and Jurisdiction</h3>
                                <p><span>{product}</span> is run out of the US. This Agreement shall be governed by the laws of the State of Delaware. If you use this Site outside the USA, you are responsible for following applicable local laws.</p>
                            </div>
                        </div>
                        <br/>
                        <div className="row">
                            <div className="col-sm-12">
                                <h3>17. Entire Agreement</h3>
                                <p>This is the entire agreement between you and Woyera relating to the subject matter herein and will not be modified except in writing, signed by both parties, or by a change to these Terms or Guidelines made by Woyera as set forth in section 1 above.
                                </p>
                            </div>
                        </div>
                        <br/><br/><br/><br/>
                    </div>
                    <div className="col-sm-1"> </div>
                </div>
            </div>
        )
    }
}

export default TermsAndConditions