import React, {Component} from 'react'
import {Button, Icon, Message} from "semantic-ui-react";
import API_Root from "../Constants/API_Root";


class FailedPaymentMessage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            message:false,
            failDt:''
        }
    }


    componentDidMount() {
        const email = localStorage.getItem("email")
        const token = localStorage.getItem("token")

        if (email !== null && token !== null){
            const isError = () => this.setState({message:false, failDt:''})

            fetch(API_Root + "api/get-user-fail-date/", {
                method: 'POST',
                headers: {
                    Accept: 'application/json, text/plain, */*',
                },
                body: JSON.stringify({
                    email: localStorage.getItem("email"),
                    token: localStorage.getItem("token"),
                })
            }).then(res => res.json())
                .then((data)=> {
                    let failDt = data.failDt

                    if (failDt !== "1970-01-01"){
                        this.setState({message:true,failDt:failDt})
                    }
                }).catch(err => isError()).catch(err => isError())
        }
    }

    render() {
        if (this.state.message){
            console.log("payment message")
            let failDt = this.state.failDt
            let failDtDate = new Date(failDt + " 23:00:00+0000")
            let currDt = new Date()

            let difference = failDtDate.getTime() - currDt.getTime();
            let days = Math.ceil(difference/ (1000 * 3600 * 24));

            let s = days === 1 ? "" : "s"

            return (
                <div>
                    <Message negative>
                        <Message.Header>Your Payment For Your Monthly Subscription Has Failed :( </Message.Header>
                        <p> You have {days} day{s} to update your payment method. Your account benefits will expire on {failDtDate.toLocaleDateString()} at {failDtDate.toLocaleTimeString().replace(":00","")} </p>

                        <Button size="large" style={{backgroundColor: "navy"}}><a href="/update-payment-info" style={{textDecoration: "none", color: "white"}}><Icon style={{color:'white'}} name="payment" /> Update Payment Info</a></Button>
                    </Message>
                </div>
            )
        }else{
            return null
        }


    }
}

export default FailedPaymentMessage