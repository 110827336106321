import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Button } from 'semantic-ui-react'
import API_Root from "../../Constants/API_Root";
import {modifyFileInformation} from "../../Actions/ModifyFileInformation";
import {toggleModal} from "../../Actions/ToggleModal";

class EditFileReadButton extends Component {
    constructor(props) {
        super(props);

        this.state = {
            reading: false
        }
    }

    // Add empty skip rows when sending
    readFile = (e) => {
        this.setState({ reading: true })

        const websocketRoute = API_Root.substring(0, 5) === "https" ? API_Root.replace("https", "wss") : API_Root.replace("http", "ws");
        const socket = new WebSocket(websocketRoute + "ws/api/read-split-file/");

        const props = this.props;
        const editOptions = JSON.parse(JSON.stringify(this.props.editOptions))

        editOptions['skipRows'] = ""
        editOptions['sheetName'] = this.props.sheetName

        // Send the PKs and if any column names
        socket.onopen = (event) => {
            socket.send(JSON.stringify({
                filePK: props.filePK,
                size: props.fileInformation['size'],
                editOptions: editOptions
            }));
        };

        socket.onmessage = (event) => {
            const reply = JSON.parse(event.data);
            const status = reply['status'];

            this.setState({ reading: false })

            if (status === "read"){
                let fileInformation = reply.fileInformation;
                props.modifyFileInformation(fileInformation)
                props.toggleModal(null)
                socket.close()
            }
            else if (status === "error"){
                props.toggleModal(null)
                socket.close()
                alert("There was a problem re-reading your file. Please try again")
            }
        };

    }

    render() {
        return (
            <div>
                <Button size="large"
                        color="blue"
                        icon="file alternate outline"
                        content="Re-read File"
                        loading={this.state.reading}
                        disabled={this.props.editOptions.startRowError}
                        onClick={this.readFile} />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    editOptions: state.mainState.editOptions,
    filePK: state.mainState.filePK,
    fileInformation: state.mainState.fileInformation
})

const mapActionsToProps = {
    modifyFileInformation: modifyFileInformation,
    toggleModal: toggleModal
}

export default connect(mapStateToProps, mapActionsToProps)(EditFileReadButton)