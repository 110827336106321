const currencyInfo = {
    usd: {
        symbol: "$",
        text: "US Dollar",
        multiplier: 1
    },
    aud: {
        symbol: "AUD$",
        text: "Australian Dollar",
        multiplier: 1.5
    },
    brl: {
        symbol: 'R$',
        text: "Brazilian Real",
        multiplier: 2
    },
    gbp: {
        symbol: "£",
        text: "British Pound",
        multiplier: 0.75
    },
    sgd: {
        symbol: 'SGD$',
        text: "Singapore Dollar",
        multiplier: 1.25
    },
    eur: {
        symbol: '€',
        text: "Euro",
        multiplier: 1
    },
    pln: {
        symbol: 'zł',
        text: 'Polish Zloty',
        multiplier: 3
    },
    inr: {
        symbol: '₹',
        text: 'Indian Rupee',
        multiplier: 20.25
    },
    cad: {
        symbol: 'CAD$',
        text: 'Canadian Dollar',
        multiplier: 1.25
    },
    jpy: {
        symbol: '¥',
        text: 'Japanese Yen',
        multiplier: 100
    },
    php: {
        symbol: '₱',
        text: 'Philippine Peso',
        multiplier: 20
    },
    try: {
        symbol: '₺',
        text: 'Turkish Lira',
        multiplier: 3.5
    }
}

export default currencyInfo
