import React, {Component} from 'react'
import FAQBlock from "./FAQBlock";
import { connect } from 'react-redux'
import {toggleModal} from "../../Actions/ToggleModal";
import { Icon } from 'semantic-ui-react';

class HelpDisplay extends Component {
    render() {
        const uploadFAQs = [
            {
                question: "How do I upload the file to split?",
                answer: "You click on the big dialog and upload the file or you can drag and drop it in the box",
            },
            {
                question: "What kind of file can I upload?",
                answer: "You can upload a file with these extensions: .xls, .xlt, .xlm, .xlsx, .xlsm, .xltx, .xltm, .xlsb, .csv, .txt"
            },
            {
                question: "How many files can I upload?",
                answer: "You can only upload 1 file at a time to split. You can upload as many times as you want. " +
                "However, on the Free tier, you can only split 1 file per hour."
            },
            {
                question: "What size file can I split?",
                answer: "There is no maximum limit. However, on the Free tier you can only split a file up to 5 MB."
            },
        ];

        const readFAQs = [
            {
                question: "How are sheets in Excel Files read?",
                answer: <p>We automatically read the first sheet. You can read any other sheet by selecting it in the <b><Icon name="edit alternate outline" /> Read File Options</b> dialog</p>},
            {
                question: "How are CSV files read?",
                answer: <p>They are automatically read with a comma delimiter. You can change the delimiter in the <b><Icon name="edit alternate outline" /> Read File Options</b> dialog</p>
            },
            {
                question: "How do you find the column names?",
                answer: <div>
                    <p>We read the first row in the file as the header. You can change that in the <b><Icon name="edit alternate outline" /> Read File Options</b> dialog using the <b>Start at Row</b> and <b>Does File Have a Header?</b> Options</p>
                    <p>The row specified in <b>Start at Row</b> is where the file is read from</p>
                    <p>If <b>Does File Have a header?</b> is selected, then the row the file is read from will be the header row</p>
                    <p>If <b>Does File Have a header?</b> is not selected, then a generic header will be given (Col1, Col2, etc.)</p>
                </div>
            },
            {
                question: "It is taking too long to read my file. Help!",
                answer: "Depending on your internet connection and the size of the file, it can take some time. If it has been way too long, we suggest refreshing or trying again in a different browser"
            },
        ];

        const splitFAQs = [
            {
                question: "What does the Include Header in Split File option do?",
                answer: <p>If checked, it adds the Header to EACH split file. The Header is default the first row but you can change it in the <b><Icon name="edit alternate outline" /> Read File Options</b></p>
            },
            {
                question: "How do the rows in each file work?",
                answer: <div>
                            <p>Let's take example of a file called "Awesome.csv" that has <b>2,673 rows</b> including a Header on <b>Row 1</b></p>
                            <p>Due to the Header, this means there are <b>2,672</b> rows of Data in the file</p>
                            <p>We specify that we want to <b>Include Header in Split File</b> and have <b>1000 rows</b> in each split file</p>
                            <p>After splitting the file, we will get 3 files with the following breakdowns:</p>
                            <ul>
                                <li><b>Awesome_1.csv</b> will have the Header from <b>Row 1</b> and Data from <b>Row 2</b> to <b>Row 1001</b>. 1001 total rows: 1 Header Row & 1000 Data Rows</li>
                                <li><b>Awesome_2.csv</b> will have the Header from <b>Row 1</b> and Data from <b>Row 1002</b> to <b>Row 2001</b>. 1001 total rows: 1 Header Row & 1000 Data Rows</li>
                                <li><b>Awesome_3.csv</b> will have the Header from <b>Row 1</b> and Data from <b>Row 2002</b> to <b>Row 2673</b>. 673 total rows: 1 Header Row & 672 Data Rows</li>
                            </ul>
                            <br/>
                            <p>If we DO NOT select the <b>Include Header in Split File</b> option, then the 3 files above will be as follows</p>
                            <ul>
                                <li><b>Awesome_1.csv</b> will have the Data from <b>Row 2</b> to <b>Row 1001</b>. 1000 total rows: No Header Row & 1000 Data Rows</li>
                                <li><b>Awesome_2.csv</b> will have the Data from <b>Row 1002</b> to <b>Row 2001</b>. 1000 total rows: No Header Row & 1000 Data Rows</li>
                                <li><b>Awesome_3.csv</b> will have the Data from <b>Row 2002</b> to <b>Row 2673</b>. 672 total rows: No Header Row & 672 Data Rows</li>
                            </ul>
                    </div>
            },
            {
                question: "Can I specify wanting different records in each file?",
                answer: "We assume you mean specify 1000 records in the first file, 2000 in the second and so on. We currently don't have this on our roadmap but feel free to provide us Feedback if you want to see this feature"
            },
            {
                question: "Can I specify a custom header on the tool?",
                answer: "We currently don't have this on our road map but feel free to provide us Feedback if you want to see this feature"
            },
            {
                question: "It is taking too long to split my file. Help!",
                answer: "Depending on the size of the file, it can take some time. If it has been way too long, we suggest refreshing or trying again in a different browser"
            },
        ];

        const downloadFAQs = [
            {
                question: "What format are the split files downloaded in?",
                answer: "The split files are downloaded in a .ZIP folder and each file is a .CSV format with comma (,) as the delimiter"
            },
            {
                question: "What does the remove duplicates option do?",
                answer: "For all identical records found after the file has been merged, only the first record gets kept. The Append File Sample does NOT reflect this. It only affects the final file."
            },
            {
                question: "How do I open .CSV files?",
                answer: "You can open them in MS Excel or any other Spreadsheet software"
            },
            {
                question: "Can I download the files in another format?",
                answer: "We currently don't have plans to support this feature. However, you can open the file in MS Excel or another spreadsheet software and Save As a different format"
            },
        ];

        const securityFAQs = [
            {
                question: "How do I know my data is secure?",
                answer: ["We encrypt data in transit using SSL", "We encrypt data on our server", "We delete all uploaded files hourly"]
            },
            {
                question: "I still don't feel comfortable",
                answer: "We totally understand. If it helps, you can read our Terms and Conditions and Privacy Policy. We explicitly state we do not and will not ever sell or give your data to any third party. If you still feel uncomfortable but want to use the tool, contact us at info@splitspreadsheets.com and we can provide you a downloadable version of the app."
            }
        ];

        const topicFAQs = {
            upload: {title: "Upload", icon: "upload", faq: uploadFAQs},
            read: {title: "Read", icon: "book", faq: readFAQs},
            split: {title: "Split", icon: "cut", faq: splitFAQs},
            download: {title: "Download", icon: "download", faq: downloadFAQs},
            security: {title: "Security", icon: "lock", faq: securityFAQs},
        }

        const topic = topicFAQs[this.props.topic];

        return (
            <div>
                <FAQBlock title={topic.title} icon={topic.icon} FAQs={topic.faq}/>
            </div>
        )
    }
}

const mapActionsToProps = {
    toggleModal: toggleModal
}

export default connect(null, mapActionsToProps)(HelpDisplay)