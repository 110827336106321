import React, {Component} from 'react'
import {connect} from 'react-redux'
import ModalApp from "../SharedComponents/Modal"
import { Table, Icon } from 'semantic-ui-react'
import isObjectEmpty from "../Constants/isObjectEmpty";

class SampleDataModal extends Component {
    render() {
        let body = null;

        if (!isObjectEmpty(this.props.fileInformation)){
            if ('samples' in this.props.fileInformation && 'columnNames' in this.props.fileInformation){
                let tableRows = [];

                for (let i=0; i<this.props.fileInformation['samples'].length; i++){
                    let tableCols = [];

                    for (let j=0; j<this.props.fileInformation['samples'][i].length; j++){
                        tableCols.push(<Table.Cell key={i.toString() + j.toString()}>{this.props.fileInformation['samples'][i][j]}</Table.Cell>)
                    }

                    tableRows.push(<Table.Row>{tableCols}</Table.Row>)
                }

                body =
                <div>
                    <h2><Icon name="table"/> Showing first {tableRows.length.toString()} rows from your file</h2>
                    <div style={{overflowX: "scroll"}}>
                        <Table celled>
                            <Table.Header>
                                <Table.Row>
                                    {this.props.fileInformation['columnNames'].map((x, idx) => <Table.HeaderCell key={idx.toString()}>{x}</Table.HeaderCell>)}
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {tableRows}
                            </Table.Body>
                        </Table>
                    </div>
                </div>
            }
        }

        return (
            <ModalApp
                name="sampleDataModal"
                header={null}
                body={body}
                size="large"
            />
        )
    }
}

const mapStateToProps = (state) => ({
    fileInformation: state.mainState.fileInformation
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(SampleDataModal)