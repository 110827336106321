import React, {Component} from 'react'
import {connect} from 'react-redux'
import UploadFilesIndex from './UploadFiles/UploadFilesIndex'
import FileOptions from "./FileOptions"
import DownloadSplitButton from "./DownloadSplits/DownloadSplitButton"
import ProductQualityFeature from './ProductQualityFeature'
import FAQBlock from "./FAQBlock";
import {modifyCurrency} from "../Actions/ModifyCurrency";
import getCurrency from "../Constants/getCurrency"
import {isEmbed} from "../Constants/isEmbed";

class AppIndex extends Component {
    componentDidMount(){
        if (!isEmbed(window.location.href)){
            (function(){
                const s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
                s1.async=true;
                s1.src='https://embed.tawk.to/5e224f95daaca76c6fce9ae8/default';
                s1.charset='UTF-8';
                s1.setAttribute('crossorigin','*');
                s0.parentNode.insertBefore(s1,s0);
            })();
        }

        fetch("https://api.ipdata.co?api-key=279fd964a008f067fe1b6b5855b155947b4724b16eaf38223b89b77b").then(res => res.json()).then(
            (data) => {
                const currencyCode = getCurrency(data);
                this.props.modifyCurrency(currencyCode);
            }).catch((err) => {this.props.modifyCurrency("usd")})
    }

    render() {
        return (
            <div className="app">
                <div className="row" style={{marginTop: "25px"}}>
                    <div className="col-sm-1">

                    </div>
                    <div className="col-sm-5">
                        <UploadFilesIndex />
                    </div>
                    <div className="col-sm-5">
                        <FileOptions />
                    </div>
                    <div className="col-sm-1">
                    </div>
                </div>
                {/*<div style={{marginTop: '70px'}}>*/}
                    {/*<div className="row">*/}
                        {/*<div className="col-sm-12">*/}
                            {/*<h2 className="thinHeading" style={{textAlign: 'center', marginBottom: '20px'}}>Why Use This Tool?</h2>*/}
                        {/*</div>*/}
                    {/*</div>*/}
                    {/*<div className="row">*/}
                        {/*<div className="col-sm-4">*/}
                            {/*<ProductQualityFeature*/}
                                {/*id="codeFree"*/}
                                {/*iconName="paper plane outline"*/}
                                {/*title="Simple"*/}
                                {/*desc="Specify only a few things and get all your split files"*/}
                            {/*/>*/}
                        {/*</div>*/}
                        {/*<div className="col-sm-4">*/}
                            {/*<ProductQualityFeature*/}
                                {/*id="quality"*/}
                                {/*iconName="lock"*/}
                                {/*title="Secure"*/}
                                {/*desc="All processing is done in the browser so no security issues"*/}
                            {/*/>*/}
                        {/*</div>*/}
                        {/*<div className="col-sm-4">*/}
                            {/*<ProductQualityFeature*/}
                                {/*id="security"*/}
                                {/*iconName="code"*/}
                                {/*title="Stable"*/}
                                {/*desc="Handle super large files without freezing or hanging"*/}
                            {/*/>*/}
                        {/*</div>*/}
                    {/*</div>*/}
                {/*</div>*/}
                {/*<div style={{marginTop: '70px'}}>*/}
                    {/*<div className="row">*/}
                        {/*<div className="col-sm-12">*/}
                            {/*<h2 className="thinHeading" style={{textAlign: 'center', marginBottom: '20px'}}>FAQs</h2>*/}
                        {/*</div>*/}
                    {/*</div>*/}
                    {/*<div className="row">*/}
                        {/*<div className="col-sm-4">*/}
                            {/*<FAQBlock*/}
                                {/*question="What does the header option do?"*/}
                                {/*answer={<p>If checked, it adds the first row of the file as the first row of each split file</p>}*/}
                            {/*/>*/}
                        {/*</div>*/}
                        {/*<div className="col-sm-4">*/}
                            {/*<FAQBlock*/}
                                {/*question="How do the specified number of records work?"*/}
                                {/*answer={<div><p>The number of rows is divided into files that each have the number of rows specified. Any leftovers are put in the final file. If header is checked, there is also 1 extra row for the header after the 1st file.</p>*/}
                                    {/*<p>For example, if a 10,324 row file called "Awesome.csv" is split with 5,000 rows and header is checked, the breakdown will be as follows:*/}
                                        {/*<ul>*/}
                                            {/*<li><b>5000_Awesome.csv</b> will have the first 5000 records with row 1 being the header. 5000 total rows</li>*/}
                                            {/*<li><b>10000_Awesome.csv</b> will have rows 5001-10000 (5000) with an additional header row. 5001 total rows</li>*/}
                                            {/*<li><b>10324_Awesome.csv</b> will have rows 10001-10324 (324) with an additional header row. 325 total rows</li>*/}
                                        {/*</ul>*/}
                                    {/*</p>*/}
                                {/*</div>}*/}
                            {/*/>*/}
                        {/*</div>*/}
                        {/*<div className="col-sm-4">*/}
                            {/*<FAQBlock*/}
                                {/*question="When is support for xlsx etc. formats coming?"*/}
                                {/*answer={<p>Soon!</p>}*/}
                            {/*/>*/}
                        {/*</div>*/}
                    {/*</div>*/}
                {/*</div>*/}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {
    modifyCurrency: modifyCurrency
}

export default connect(mapStateToProps, mapActionsToProps)(AppIndex)