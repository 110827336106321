import React, {Component} from 'react'
import { Button } from 'semantic-ui-react'
import { connect } from 'react-redux'
import {toggleModal} from "../../Actions/ToggleModal";
import {push} from "react-router-redux";

class NavbarLogIn extends Component {
    constructor(props) {
        super(props);

        this.logIn = this.logIn.bind(this);
    }

    logIn(e){
        this.props.toggleModal("loginModal")
    }

    render() {
        return (
            <Button basic color="purple" onClick={this.logIn} style={{marginTop: '2px'}}>
                Log In
            </Button>
        )
    }
}

const mapActionsToProps = {
    navigateTo: push,
    toggleModal: toggleModal
}

export default connect(null,mapActionsToProps)(NavbarLogIn);