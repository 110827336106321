import React, {Component} from 'react'
import ModalApp from '../../SharedComponents/Modal'
import ProfileModalBody from './ProfileModalBody'

class ProfileModal extends Component {
    render() {

        return (
            <ModalApp name="profileModal"
                      header="Account Details"
                      body={<ProfileModalBody/>}
                      size="large"
            />
        )
    }
}

export default ProfileModal