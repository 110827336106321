import React, {Component} from 'react'

class PricingFeature extends Component {
    render() {
        return (
            <div className="pricingFeature">
                <h3 className="thinHeading">{this.props.title}</h3>
                <img src={this.props.img} style={{width: '25%', marginTop: '10px'}} alt="pricingFeaturePicture"/>
                <br/><br/>
                {this.props.desc}
            </div>
        )
    }
}

export default PricingFeature