import React, {Component} from 'react'
import { Icon } from 'semantic-ui-react'

class ProductQualityFeature extends Component {
    render() {
        return (
            <div id={this.props.id}>
                <div className="productQualityFeatureDiv">
                <h5>
                    <Icon name={this.props.iconName} />
                    <span style={{marginLeft: '7px'}}>{this.props.title}</span>
                </h5>
                    <p>{this.props.desc}</p>
                </div>
            </div>
        )
    }
}

export default ProductQualityFeature