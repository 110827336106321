import React, {Component} from 'react'
import { Button } from 'semantic-ui-react'
import {push} from "react-router-redux";
import { connect } from 'react-redux'
import {toggleModal} from "../../Actions/ToggleModal";

class NavbarSignUp extends Component {
    constructor(props) {
        super(props);

        this.signUp = this.signUp.bind(this);
    }

    signUp(e){
        this.props.toggleModal("signUpModal")
    }

    render() {
        let fontSize = null;

        if ('fontSize' in this.props){
            fontSize = this.props.fontSize
        }

        return (
            <Button color="blue" onClick={this.signUp} style={{marginTop: '2px', fontSize: fontSize}}>
                Sign Up Free
            </Button>
        )
    }
}

const mapActionsToProps = {
    navigateTo: push,
    toggleModal: toggleModal
}

export default connect(null,mapActionsToProps)(NavbarSignUp)