import React, {Component} from 'react'
import { Menu, Icon, Input } from 'semantic-ui-react'

class HelpMenu extends Component {
    constructor(props) {
        super(props);

        this.changeMenuItem = this.changeMenuItem.bind(this);
    }

    changeMenuItem(e, {name}){
        this.props.changeHelpMenu(name)
    }

    render() {
        const topicMenu = [
            {name: 'upload', icon: 'upload', desc: 'Upload File'},
            {name: 'read', icon: 'book', desc: 'Read the File'},
            {name: 'split', icon: 'cut', desc: 'Split the File'},
            {name: 'download', icon: 'download', desc: 'Download Split Files'},
            {name: 'security', icon: 'lock', desc: 'Data Privacy & Security'}
        ]

        const menuItems = topicMenu.map((x, idx) =>
            <Menu.Item
            name={x.name}
            active={this.props.topic === x.name}
            onClick={this.changeMenuItem}
            key={idx}
            >
                <Icon name={x.icon} /> {x.desc}
            </Menu.Item>
        )

        return (
            <div style={{paddingLeft: '50px'}}>
                <Menu pointing secondary vertical>
                    {menuItems}
                </Menu>
            </div>
        )
    }
}

export default HelpMenu